import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Section, Em } from "@quarkly/widgets";
const defaultProps = {
	"padding": "60px 0 0 0",
	"md-padding": "60px 0 30px 0",
	"quarkly-title": "Footer-15",
	"box-shadow": "--l"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"margin": "0px 0px 50px 0px",
			"grid-template-columns": "repeat(4, 1fr)",
			"grid-gap": "36px 34px",
			"lg-grid-template-columns": "repeat(3, 1fr)",
			"lg-flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "33%",
			"padding": "0px 50px 0px 0",
			"md-width": "90%",
			"sm-margin": "40px 0px 0px 0",
			"sm-width": "100%",
			"sm-padding": "0px 0px 0px 0",
			"margin": "0px 0 0px 0px",
			"lg-width": "100%",
			"lg-padding": "0px 0 0px 0",
			"min-height": "auto"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 18px/1.5 --fontFamily-sans",
			"letter-spacing": "1px",
			"text-transform": "uppercase",
			"children": "Чистка BioClean"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"color": "#5a5d64",
			"lg-max-width": "640px",
			"text-transform": "uppercase",
			"children": <Em>
				Делаем каждое пространство безупречным
			</Em>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "auto",
			"margin": "0px 0 0px 0px",
			"width": "33%",
			"padding": "0px 0 0px 0px",
			"lg-width": "30%",
			"md-width": "45%",
			"sm-width": "100%"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 18px/1.5 --fontFamily-sans",
			"letter-spacing": "1px",
			"children": "КОНТАКТНАЯ ИНФОРМАЦИЯ"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"display": "block",
			"margin": "0px 0px 15px 0px",
			"hover-color": "--primary",
			"href": "+77766885131",
			"children": "+77766885131"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"display": "inline-block",
			"margin": "0px 0px 15px 0px",
			"hover-color": "--primary",
			"href": "nexolight.com",
			"children": "info@nexolight.com"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "auto",
			"margin": "0px 0 0px 0",
			"width": "33%",
			"lg-width": "30%",
			"md-width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-end"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 18px/1.5 --fontFamily-sans",
			"letter-spacing": "1px",
			"children": "НАВИГАЦИЯ"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "/index",
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"margin": "0px 0px 5px 0px",
			"display": "inline-block",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"hover-color": "--primary",
			"children": "ГЛАВНАЯ"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "/services",
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"margin": "0px 0px 5px 0px",
			"display": "inline-block",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"hover-color": "--primary",
			"children": "УСЛУГИ"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"href": "/contacts",
			"color": "#5a5d64",
			"text-decoration-line": "initial",
			"margin": "0px 0px 5px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"display": "inline-block",
			"hover-color": "--primary",
			"children": "КОНТАКТЫ"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" height="auto" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text2")} />
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text3")} />
				<Link {...override("link2")} />
				<Link {...override("link3")} />
				<Link {...override("link4")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;